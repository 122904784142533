@import '../../styles/variables.scss';

.ballon {
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 1;

  padding: 7px 10px;

  font-size: 13px;
  letter-spacing: -0.2px;

  color: $white-default;

  background-color: $default;
  border-radius: 2px;
  transform: translateX(-50%);
  opacity: 0.6;
}
