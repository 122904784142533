@import '../../../styles/variables.scss';

.meetings {
  &__title {
    padding-bottom: 20px;
    padding-left: 16px;

    font-family: $font-semi-bold;
    font-size: 13px;
    font-weight: lighter;

    color: $gray-default;
    text-transform: uppercase;
  }

  &__meetings-list {
    width: 380px;
    height: 101%;
    padding-top: 20px;
  }

  &__meetings-list-container {
    width: 404px;
  }

  &__description-container {
    margin: 0 0 0 36px !important;
  }
}
