@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.meetings-type {
  height: 100%;
  min-width: 184px;
  padding-top: 22px;

  border-right: 1px solid $gray-border;

  &__title {
    @include h3;
    max-width: 130px;
    padding-right: 23px;
    margin: 0 30px;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;

    background-image: url('../../assets/images/down-img.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 13px 8px;
  }

  &:hover &__title {
    background-image: url('../../assets/images/up-img.svg');
  }

  &__dropdown {
    right: auto;
    left: 0;

    width: 280px !important;
    padding: 20px;
  }
}

.report-types {
  padding: 10px 10px 10px;

  .label {
    position: relative;

    display: block;
    padding-left: 35px;

    font-family: $font-default;
    font-size: 15px;

    color: $black-default;
    cursor: pointer;
    user-select: none;
  }

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    background: $white-default;
    border: 1px solid $green-default;
    border-radius: 2px;

    &:after {
      position: absolute;

      display: none;
      content: '';
    }
  }

  .label .checkmark:after {
    top: 5px;
    top: 50%;
    left: 9px;
    left: 50%;

    width: 12px;
    height: 10px;
    content: '';

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 13 10'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M11 0L4.8 7.4 1.2 3.633 0 4.833C3.2 8.144 4.822 9.8 4.867 9.8c.044 0 2.489-2.889 7.333-8.667L11 0z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  .checkbox:checked ~ .checkmark {
    background: $green-default;
  }

  .checkbox:checked ~ .checkmark:after {
    display: block;
  }
}
