$bgcolor: blue;
$usersBlockColor: #f2f2f2;
$icoColor: #777;
$preloader: #337ab7;
$error: #f93232;

$defaultColor: #f2f2f2;
$meetingBlockColor: #f5f5f5;
$meetingBorderColor: #ddd;
$borderColor: $meetingBorderColor;
$borderFocus: #66afe9;
//text
$text-color-default: #555;
$text-color-active: #337ab7;
$text-color-success: #00b956;
$text-color-error: #eb1b1b;
$link-color: #337ab7;
//images
$member-list-photo-width: 30px;
$member-list-photo-height: 30px;
$user-list-photo-width: 60px;
$user-list-photo-height: 60px;
$base-line-height: 22px/15px;

$font-default: 'Open Sans Regular';
$font-semi-bold: 'Open Sans Semibold';
$font-bold: 'Open Sans Bold';

$default: #333;

$black-default: #333;

$checkbox-bg: #d8d8d8;

$gray-border: #d8d8d8;
$gray-default: #999;
$gray-dark: #727272;
$gray-hover: #c8c8c8;
$gray-box: #f7f7f7;

$white-default: #fff;

$green-default: #00b956;
$green-disabled: #009444;
$green-buttons-hover: #34c778;
$green-ava: #73e6a8;
$green-hover-text: #d7fce8;
$green-hover-menu: #d7fce8;
$green-bg-marker: #29cc29;
$green-export-button: #14892c;
