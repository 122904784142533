@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.files-list {
  position: relative;

  &__title-wrapper {
    display: inline-block;
    cursor: pointer;
    user-select: none;

    &--show {
      margin-bottom: 0;
    }
    &--hide {
      margin-bottom: 10px;
    }
  }

  &__title {
    display: inline-block;
    padding-right: 10px;

    font-family: $font-bold;
    font-size: 17px;

    color: $black-default;
  }

  &__counter {
    font-family: $font-bold;
    font-size: 17px;

    color: $gray-default;
  }

  &__image-container {
    outline: 1px solid $gray-border;

    border: 3px solid $white-default;
  }

  &__image {
    display: block;
    width: 112px;
    height: 160px;
    margin: 0 auto;

    img {
      z-index: -1;

      font-size: 10px;
      object-fit: cover;
    }
  }

  &__image-default {
    position: relative;

    width: 112px;
    height: 160px;
  }

  &__image-default-ico {
    fill: $white-default;

    width: 48px;
    height: 32px;

    @include vertical-align-center;
  }

  &__image-excel {
    position: relative;

    width: 114px;
    height: 160px;

    background-color: #d8d8d8;
  }

  &__image-download-ico {
    fill: $white-default;

    width: 45px;
    height: 45px;

    @include vertical-align-center;
  }

  &__image-excel-ico {
    fill: $white-default;

    width: 49px;
    height: 65px;

    @include vertical-align-center;
  }
  &__original-button {
    display: inline-block;
    width: 80px;

    padding: 2.5px 5px;

    margin-top: 10px;

    font-size: 11px;

    color: $green-export-button;

    background-color: transparent;

    border: 1px solid $green-export-button;
    border-radius: 14px;
    cursor: pointer;
  }

  &__description {
    height: 36px;
    margin-top: 15px;
    overflow: hidden;

    font-family: $font-default;
    font-size: 13px;

    color: $black-default;
  }

  &__list {
    padding-top: 35px;

    &--with-height {
      height: 320px;
      overflow: hidden;
    }

    &--hidden {
      display: none;
    }
  }

  &__item {
    position: relative;

    display: inline-block;
    width: 120px;
    padding-bottom: 40px;
    margin-right: 20px;

    text-align: center;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }

    &__progress-bar {
      margin-top: 10px;
    }
  }

  &__show-more {
    margin-bottom: 60px;
  }

  &__link {
    position: relative;

    display: block;

    cursor: pointer;
  }

  &__button {
    position: absolute;
    top: 60px;
    right: 0;
  }

  &__labels-container {
    position: absolute;
    top: 140px;
    right: -10px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  &__type {
    margin-left: 5px;
    width: 28px;
    height: 28px;

    background-position: center;
    background-repeat: no-repeat;

    &--type-new {
      z-index: 2;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='14' cy='14' r='14' fill='%23731982'/%3E%3Cpath fill='%23FFF' d='M9.7 17.425H7.968l-2.795-4.86h-.04c.056.858.084 1.47.084 1.836v3.024H4V11h1.718l2.79 4.812h.032a38.96 38.96 0 0 1-.066-1.771V11H9.7v6.425zm4.817 0h-3.7V11h3.7v1.116H12.18v1.41h2.175v1.117H12.18V16.3h2.338v1.125zm7.85 0h-1.552l-.87-3.375a16.43 16.43 0 0 1-.165-.745 8.502 8.502 0 0 1-.134-.758 9.98 9.98 0 0 1-.132.762c-.07.35-.124.6-.162.75l-.866 3.366H16.94L15.3 11h1.34l.822 3.507c.144.647.248 1.208.312 1.683.018-.167.058-.426.121-.776.063-.35.122-.622.178-.815L19.009 11h1.288l.936 3.6c.04.16.092.406.153.737.062.331.109.616.141.853.03-.229.076-.513.14-.855.065-.341.124-.617.177-.828L22.66 11H24l-1.635 6.425z'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &--type-comment {
      z-index: 2;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='14' cy='14' r='14' fill='%23FFA717'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M20.5 10v7.104a1 1 0 0 1-1 1h-1a.5.5 0 0 0-.5.5V20.5a.5.5 0 0 1-.801.4L13.5 18.103h-5a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1zm-10 4.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3.5 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3.5 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &--type-archive {
      z-index: 2;
      background-image: url('../../assets/images/archive-file-img.svg');
    }

    &--type-child {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;

    }

  }

  &__extension {
    position: relative;
    // top: 140px;
    // right: -10px;
    z-index: 1;

    height: 28px;
    padding: 0 5px;
    margin-left: 5px;

    font-size: 9px;
    font-weight: bold;
    line-height: 28px;
    color: #fff;

    background-color: #999;
    border-radius: 14px;

    &--long {
      padding: 0 35px 0 7px;
    }
  }

  &__visibility {
    display: inline-block;
    height: 12px;
    min-width: 13px;
    min-height: 8px;
    margin-left: 16px;

    background-position: right center;
    background-repeat: no-repeat;
    background-size: 13px 8px;
    cursor: pointer;

    &--show {
      background-image: url('../../assets/images/up-img.svg');
    }
    &--hide {
      background-image: url('../../assets/images/down-img.svg');
    }
  }

  &__responsible {
    cursor: pointer;

    margin-top: 15px;
    overflow: hidden;

    font-family: $font-default;
    font-size: 13px;

    color: $gray-default;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
