@import '../../styles/variables.scss';

.document-comment {
  position: absolute;
  z-index: 3;

  &__content-container {
    width: 400px;
  }

  &__comment-container {
    padding: 20px;

    background: $white-default;
  }

  &__comment {
    resize: none;

    width: 100%;

    font-family: $font-default;
    font-size: 13px;

    color: $black-default;
  }

  &__time {
    font-size: 11px;

    color: $gray-default;
  }

  &__navigate-container {
    display: flex;
    padding: 20px;

    background: $gray-box;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__button {
    padding: 0 6px 0 0;

    font-family: $font-semi-bold;
    font-size: 13px;

    color: $gray-dark;
    text-transform: uppercase;

    background: transparent;

    &--green {
      color: $green-default;
    }
  }

  &__text {
    font-family: $font-default;
    font-size: 13px;
    color: $black-default;
  }

  &__checkbox-input-container {
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__checkbox-input-container > &__checkbox {
    display: none;
  }

  &__custom-checkbox {
    position: relative;

    display: inline-block;
    float: right;
    width: 32px;
    height: 16px;

    background: $checkbox-bg;
    border-radius: 16px;
    transition: 0.25s 0.09s;
    box-sizing: initial;

    &:after {
      position: absolute;
      top: 50%;
      left: 2px;

      display: block;
      width: 12px;
      height: 12px;

      background: $white-default;
      border-radius: 50%;
      content: ' ';
      transition: 0.25s;
      transform: translateY(-50%);
    }
  }

  &__checkbox:checked + &__custom-checkbox {
    background: $green-default;
  }

  &__checkbox:checked + &__custom-checkbox:after {
    transform: translateX(16px) translateY(-50%);
  }
}
