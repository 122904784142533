.profile-menu {
  margin-top: 15px;

  &__button {
    display: block;
    width: 100%;

    text-align: left;

    background-color: transparent;
    outline: 0;
    cursor: pointer;
    border: 0;
  }
}
