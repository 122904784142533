@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.profile-loading {
  text-align: center;

  &__ava {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;

    border-radius: 50%;
  }

  &__welcome {
    @include name;
    margin-bottom: 40px;

    font-weight: 600;

    color: $white-default;
  }

  &__p,
  &__name {
    color: $white-default;
  }
}
