.filters {
  display: flex;
  width: 100%;
  height: 100%;

  &__date {
    margin: 23px 28px auto;
  }

  &__search {
    margin-top: 16px;
    flex-grow: 2;

    text-align: right;
  }
}
