@import '../../styles/variables.scss';

.logged-out-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;

  display: none;
  width: 100%;
  height: 100%;

  text-align: center;

  background: rgba(0, 0, 0, 0.7);

  &--open {
    display: block;
  }

  &__content-container {
    padding: 210px;
  }

  &__content {
    padding-bottom: 30px;

    font-family: $font-semi-bold;
    font-size: 15px;

    line-height: 28px;
    color: $white-default;
  }

  &__time {
    display: block;
    padding-bottom: 22px;

    font-family: $font-default;
    font-size: 34px;

    color: $yellow-errors-default;
  }

  &__button {
    width: 210px;
    height: 60px;
    padding: 0;

    font-family: $font-bold;
    font-size: 17px;

    color: $white-default;

    background: $green-default;
    border-radius: 60px;
  }
}
