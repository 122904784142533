@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.login {
  position: absolute;

  width: 100%;
  height: 100%;
  padding-top: 40px;

  background-color: $green-default;

  &__back {
    position: absolute;
    top: 45px;
    left: 30px;

    padding-left: 26px;

    color: $white-default !important;

    @include name;
  }

  &__back-ico {
    position: absolute;
    top: 4px;
    left: 0;

    width: 16px;
    height: 12px;
    fill: $white-default;
  }

  &__form {
    position: absolute;
    top: 50%;
    left: 50%;

    display: inline-block;
    transform: translate(-50%, -50%);
  }

  &__logo-bottom {
    position: absolute;
    bottom: 40px;
    left: 50%;

    width: 140px;
    height: 25px;

    background-image: url('../../../assets/images/megafon-full-logo-img.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateX(-50%);
  }
}
