@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.calendar-container {
  position: absolute !important;
  top: 100%;
  left: 0;
  z-index: 1;

  font-family: $font-default !important;

  color: $default !important;

  background-color: $white-default !important;
  border: 0 none !important;
  border-radius: 0 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06) !important;

  .react-datepicker__header {
    text-align: left !important;

    background-color: $gray-box !important;
    border-bottom: 0 none !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .react-datepicker__current-month {
    @include menu;
    padding: 10px 16px 16px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .react-datepicker__month {
    margin: 2.5 !important;
  }

  .react-datepicker__day-names {
    @include days;
    padding: 0 6px;
    margin: 6px 0 0;

    text-transform: uppercase;

    background-color: $white-default;
  }

  .react-datepicker__day--selected {
    color: $white-default !important;

    background-color: $purple-default;
  }

  .react-datepicker__day-name {
    width: 24px !important;
    margin: 4px !important;
  }

  .react-datepicker__navigation {
    width: 8px !important;
    height: 13px !important;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: 8px 13px;
    border: 0 none !important;
    opacity: 0.2;

    &--previous {
      right: 30px;
      left: auto !important;

      background-image: url('../../assets/images/left-img.svg');
    }

    &--next {
      background-image: url('../../assets/images/right-img.svg');
    }
  }
}
