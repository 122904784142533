@import '../../styles/variables.scss';

.meeting-files {
  &__container {
    position: relative;

    margin-top: 40px;
  }

  &__docs-count {
    color: $gray-default;
  }

  &__no-files-text {
    margin-top: 80px;

    font-size: 15px;
    color: $gray-default;
    text-align: center;
  }

  &__tab-list {
    padding-left: 12px;
    margin: 0;

    border-bottom: 1px solid $gray-hover;
  }

  &__tab {
    position: relative;
    bottom: -1px;

    display: inline-block;
    padding: 6px 0 18px 0;
    margin: 0 12px;

    font-size: 15px;
    font-weight: lighter;
    color: $default;

    background: transparent;
    border: 1px solid transparent;
    list-style: none;
    cursor: pointer;

    &--selected {
      font-weight: normal;

      border-bottom: 2px solid $green-default;
    }

    &:focus {
      border-color: $green-default;
      box-shadow: none;
      outline: none;
    }
    &:focus:after {
      position: absolute;
      right: -4px;
      bottom: -5px;
      left: -4px;

      height: 5px;

      background: transparent;
      content: '';
    }
  }

  &__download-all {
    position: absolute;
    top: 8px;
    right: 0;

    display: inline-block;

    font-size: 15px;
    color: $green-default;
    vertical-align: middle;
    cursor: pointer;
  }
}
