@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__left {
    display: flex;
    width: 59%;
  }

  &__right {
    width: 40%;
  }

  &__section-name {
    margin: 22px 30px;
  }

  &__filters {
    display: flex;
    height: 100%;
  }

  &__filters-date {
    margin: 23px 28px auto;
  }

  &__menu {
    display: flex;
    float: right;
    justify-content: flex-end;
  }

  &__item {
    margin-right: 30px;
  }

  &__item-link {
    @include menu;
    display: block;

    line-height: 66px;

    border-bottom: 2px solid $white-default;
    outline: 0;

    &--active {
      border-bottom-color: $green-default;
    }

    &:hover {
      color: $green-default;
    }

    &:focus {
      outline: 0;
    }
  }
}
