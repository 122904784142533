@import '../../styles/variables.scss';

.search {
  &__input {
    width: 240px;
    height: 38px;
    padding: 0 38px;

    background-color: $white-default;
    background-image: url('../../assets/images/search-img.svg');
    background-position: 14px center;
    background-repeat: no-repeat;
    background-size: 15px 16px;
    border: solid 1px $gray-border;
    border-radius: 38px;
    outline: 0;

    &:focus {
      outline: 0 !important;
    }
  }
}
