@import '../../styles/variables.scss';
@import '../mixins.scss';

.select {
  position: relative;

  &__dropdown {
    position: absolute;
    right: 0;
    z-index: 1;

    display: none;
  }

  &:hover &__dropdown {
    display: block;
    width: 140px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);

    background-color: $white-default;
  }

  &__dropdown-item {
    @include menu;
    position: relative;

    max-width: 100%;
    padding: 10px;
    overflow: hidden;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: $green-hover-menu;
    }

    &--selected {
      color: $green-default;

      &:before {
        position: absolute;
        top: 50%;
        right: 10px;

        display: block;
        width: 20px;
        height: 20px;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 13 10'%3E%3Cpath fill='#00b956' fill-rule='nonzero' d='M11 0L4.8 7.4 1.2 3.633 0 4.833C3.2 8.144 4.822 9.8 4.867 9.8c.044 0 2.489-2.889 7.333-8.667L11 0z'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        transform: translateY(-50%);
      }
    }
  }

  &__logout {
    display: inline;
    padding: 0;

    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
}
