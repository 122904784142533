@import '../../styles/variables.scss';

.logo {
  &:last-child {
    margin-bottom: 10px;
  }

  &__name {
    font-family: $font-default;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.5px;
    color: $white-default;
    text-align: center;
    font-stretch: normal;
  }

  &__dots {
    position: relative;

    display: block;
    width: 12px;
    height: 12px;
    margin: 10px auto 0;

    background-color: $purple-default;
    border: 1px solid $purple-default;
    border-radius: 50%;
    box-sizing: border-box;

    &:after {
      position: absolute;
      top: -1px;
      right: -16px;

      display: block;
      width: 10px;
      height: 10px;

      background-color: $white-default;
      border: 1px solid $white-default;
      border-radius: 50%;
      content: '';
    }

    &:before {
      position: absolute;
      top: -1px;
      left: -16px;

      display: block;
      width: 10px;
      height: 10px;

      background-color: $purple-default;
      border: 1px solid $purple-default;
      border-radius: 50%;
      content: '';
    }
  }
}
