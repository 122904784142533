@import '../../styles/variables.scss';

.no-results {
  padding-top: 60px;

  &__title {
    font-size: 20px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
  }

  &__report-icon {
    background-image: url('../../assets/images/icon_report.svg');
    background-repeat: no-repeat;
    width: 51px;
    height: 63px;
    margin: 0 auto;
  }

  &__query {
    font-family: $font-bold;
    font-size: 34px;
    color: $green-default;
  }
}
