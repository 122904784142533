@import '../../styles/variables.scss';

.meeting-description {
  position: relative;

  &__title {
    width: 80%;
    padding-bottom: 10px;
    overflow: hidden;

    font-family: $font-bold;
    font-size: 24px;

    color: $black-default;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__status {
    position: absolute;
    top: 0;
    right: 0;

    width: 120px;
    padding: 7px 0;

    font-size: 13px;

    color: $green-default;
    text-align: center;
    text-transform: uppercase;

    background: $white-default;
  }

  &__time-container {
    display: inline-block;
    padding-bottom: 20px;
  }

  &__paragraph {
    max-height: 120px;
    padding-bottom: 10px;
    overflow: hidden;

    font-family: $font-default;
    font-size: 15px;

    line-height: 28px;
    color: $black-default;
    word-break: break-all;

    &--show-full {
      max-height: inherit;
    }
  }

  &__show-more {
    display: none;

    &--show {
      display: block;
    }
  }
}
