@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mobile {
  @include height100;
  background-color: $green-default;

  &__logo {
    padding-top: 100px;

    .logo__name {
      font-family: $font-bold;
      font-size: 34px;
      letter-spacing: -1px;
    }

    .logo__dots {
      width: 20px;
      height: 20px;

      &:after,
      &:before {
        width: 18px;
        height: 18px;
      }

      &:after {
        right: -25px;
      }

      &:before {
        left: -25px;
      }
    }
  }

  &__content {
    max-width: 280px;
    padding-top: 60px;
    padding-bottom: 30px;
    margin: 0 auto;

    font-family: $font-default;
    font-size: 16px;

    color: $white-default;
    text-align: center;
  }

  &__link {
    display: block;
    margin: 0 auto;
  }

  &__logo-bottom {
    position: absolute;
    bottom: 40px;
    left: 50%;

    width: 140px;
    height: 25px;

    background-image: url('../../../assets/images/megafon-full-logo-img.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateX(-50%);
  }
}
