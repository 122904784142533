@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.reports {
  &__report-panel {
    border-radius: 5px;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  &__report-panel--active {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  }
  &__report-name {
    font-size: 15px;
    line-height: 18px;
    color: #333333;
  }
  &__report-created {
    font-size: 13px;
    line-height: 15px;
    color: #999999;
  }

  &__active-folder-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  &__active-report-header {
    margin-left: 25px;
    margin-bottom: 40px;
  }


  &__active-report-title, &__active-folder-title {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.564706px;
    color: #333333;
  }

  &__active-report-subtitle, &__active-folder-subtitle  {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    color: #333333;
  }
  &__folder {
    display: flex;
    margin-bottom: 23px;
  }

  &__folder-icon {
    background-image: url('../../../assets/images/folder-icon.svg');
    background-repeat: no-repeat;
    width: 40px;
    height: 34px;
  }
  &__folder-title {
    padding-left: 20px;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
  }

  &__folder-created {
    padding-left: 20px;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
  }
  &__breadcrumbs {
    display: flex;
    align-items: center;
  }
  &__crumb {
    margin-right: 8px;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: #999999;
    cursor: pointer;
  }
  &__arrow {
    width: 6px;
    height: 9px;
    margin-right: 8px;
    background-image: url('../../../assets/images/right-arrow-icon.svg');
    background-repeat: no-repeat;
  }
  &__back {
    width: 15px;
    height: 33px;
    margin-right: 10px;
    background-image: url('../../../assets/images/back-arrow-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  &__document, &__report-search-document {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
  }

  &__document-description {
    width: 100%;
    overflow: hidden;
  }

  &__document-title {
    font-size: 15px;
    line-height: 18px;
    color: #333333;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__document-title--openable {
    cursor: pointer;
  }

  &__document-subtitle {
    font-size: 13px;
    line-height: 15px;
    color: #999999;
    margin-left: 10px;
  }
  &__responsible {
    margin-bottom: 8px;

    font-family: $font-default;
    font-size: 13px;
    color: $gray-default;
    margin-left: 10px;

    &--name {
      display: inline;

      color: $green-default !important;
      white-space: normal !important;
    }
  }
  &__document-new {
    width: 5px;
    height: 5px;
    margin-right: 4px;
    background: #B108CF;
    border-radius: 50%;
    visibility: hidden;
  }

  &__document-new--show {
    visibility: visible;
  }


  &__preview {
    position: relative;
  }
  &__type-labels {
    position: absolute;
    z-index: 2;
    right: -8px;
    display: flex;
    flex-direction: row-reverse;
  }
  &__type-comment {

    width: 24px;
    height: 24px;
    margin-top: 45px;

    background-image: url('../../../assets/images/comment-file-img.svg');

    background-position: center;
    background-repeat: no-repeat;
  }

  &__type-archive {

    width: 24px;
    height: 24px;
    margin-top: 45px;

    background-image: url('../../../assets/images/archive-file-img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__image {
    display: block;
    width: 46px;
    height: 62px;
    margin: 0 auto;

    img {

      font-size: 10px;
      object-fit: cover;
    }
  }

  &__image-default {
    position: relative;

    width: 46px;
    height: 62px;

    border: 2px solid $white-default;
  }

  &__image-default-ico {
    width: 36px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    fill: $white-default;
  }
  &__link {
    position: relative;

    display: block;

    cursor: pointer;
  }

  &__no-files-text {
    margin-top: 80px;

    font-size: 15px;
    color: $gray-default;
    text-align: center;
  }

  &__tab-list {
    padding-left: 12px;
    margin: 0 0 17px 0;

    border-bottom: 1px solid $gray-hover;
  }

  &__tab {
    position: relative;
    bottom: -1px;

    display: inline-block;
    padding: 6px 0 18px 0;
    margin: 0 12px;

    font-size: 15px;
    font-weight: lighter;
    color: $default;

    background: transparent;
    border: 1px solid transparent;
    list-style: none;
    cursor: pointer;

    &--selected {
      font-weight: normal;

      border-bottom: 2px solid $green-default;
    }

    &:focus {
      border-color: $green-default;
      box-shadow: none;
      outline: none;
    }
    &:focus:after {
      position: absolute;
      right: -4px;
      bottom: -5px;
      left: -4px;

      height: 5px;

      background: transparent;
      content: '';
    }
  }
  &__download-button {
    cursor: pointer;
    display: flex;
    margin-right: 8px;
    margin-left: auto;
    width: 24px;
    height: 24px;

    background-image: url('../../../assets/images/export-img.svg');
  }

  &__report-search-name {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__report-search-documents {
    padding-left: 20px;
    margin-bottom: 25px;
  }

  &__report-search-document {
    cursor: pointer;
    margin-bottom: 16px;
  }

  &__report-search-document-title {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  &__report-search-document-subtitle {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    color: #999999;
    margin-bottom: 4px;
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 20px;

    display: block;
    width: 14px;
    height: 13px;
    padding: 0;

    background-color: transparent;
    background-image: url('../../../assets/images/x-img.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__report-search-result-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }
  &__report-search-result-subtitle {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    color: #999999;
    margin-bottom: 24px;
  }
}

.report-search-modal {
  width: 60%;
  padding-top: 24px;
  padding-left: 30px;
  padding-right: 5px;
  height: 600px;
  position: absolute;
  top: 15%;
  left: 20%;
  right: 20%;
  bottom: auto;
  background-color: white;
}

.report-search-overlay {
  background-color:rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
