@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.files {
  &__title {
    @include h3;
    width: 80%;

    padding-bottom: 10px;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__updated {
    font-family: $font-default;
    font-size: 13px;
    color: $black-default;
  }
}
