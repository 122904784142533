@import '../../styles/variables.scss';

.document-comments {
  width: 100%;

  &__item {
    width: 100%;
    padding: 10px 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    cursor: pointer;

    &--active {
      background: $white-default;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__number-container {
    display: flex;
    float: left;
    width: 24px;
    height: 24px;

    background: $gray-dark;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  &__number {
    font-family: $font-bold;
    font-size: 13px;
    color: $white-default;
  }

  &__comment {
    max-width: 240px;
    margin-left: 36px;

    font-family: $font-default;
    font-size: 13px;

    line-height: 22px;
    color: $black-default;
  }

  &__time {
    display: block;
    padding-top: 10px;
    padding-bottom: 25px;
    margin-left: 36px;

    font-size: 11px;

    color: $gray-default;
  }

  &__button {
    padding: 0;
    margin-bottom: 20px;
    margin-left: 36px;

    font-family: $font-semi-bold;
    font-size: 13px;

    color: $gray-default;
    text-transform: uppercase;

    background: transparent;
  }
}
