@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans');
  src: url('../assets/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Bold.woff') format('woff'),
    url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular');
  src: url('../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Regular.woff') format('woff'),
    url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Semibold');
  src: url('../assets/fonts/OpenSans-Semibold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Semibold.woff') format('woff'),
    url('../assets/fonts/OpenSans-Semibold.ttf') format('truetype');
}
