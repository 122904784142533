@import '../../styles/variables';

.document-header {
  padding: 12px 30px;

  background: $white-default;

  &__content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__information {
    text-align: center;
  }

  &__title {
    font-family: $font-semi-bold;
    font-size: 16px;
    font-weight: lighter;

    color: $black-default;
  }

  &__time {
    display: block;

    font-family: $font-default;
    font-size: 13px;

    color: $gray-default;
  }

  &__list {
    display: flex;
    width: 200px;
    flex-flow: row nowrap;
    justify-content: space-between;

    &__item {
      margin: 0 15px;
    }
  }

  &__button {
    width: 40px;
    height: 40px;
    padding: 0;

    background-color: $white-default;
    background-position: center;
    background-repeat: no-repeat;

    &--with-circle {
      background-color: $gray-box;
      border-radius: 50%;
    }

    &--active {
      background-color: $gray-hover;
    }

    &--back {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16'%3E%3Cpath fill='%23333' fill-rule='nonzero' d='M22 8.75H2.85l6.2 6.2L8 16 0 8l8-8 1.05 1.05-6.2 6.2H22z'/%3E%3C/svg%3E%0A");
    }

    &--menu {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='15' viewBox='0 0 18 15'%3E%3Cpath fill='%23333' fill-rule='evenodd' d='M5 .25h13v1.5H5V.25zM0 0h2v2H0V0zm5 6.75h13v1.5H5v-1.5zM0 6.5h2v2H0v-2zm5 6.75h13v1.5H5v-1.5zM0 13h2v2H0v-2z'/%3E%3C/svg%3E%0A");
    }

    &--download {
      display: block;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='36' viewBox='0 0 32 36'%3E%3Cpath fill='%23333' fill-rule='nonzero' d='M30 20h2v16H0V20h2v14h28V20zM15 0v20.3l-3.68-5.13-1.64 1.13 5.44 7.7h1.77l5.44-7.7-1.65-1.14L17 20.3V0h-2z'/%3E%3C/svg%3E%0A");
      background-size: 60%;
      cursor: pointer;
      transition: transform 0.3s linear;

      &:active {
        transform: scale(0.8);
      }
    }

    &--print {
      display: block;
      margin-top: 2px;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23FFF'/%3E%3Cpath fill='%23333' d='M25.5 23.667V25.5h3.667V13.583H10.833V25.5H14.5v-1.833h-1.833v-1.834h14.666v1.834H25.5zm-9.167 0v5.5h7.334v-5.5h-7.334zM14.5 27.333h-3.667A1.833 1.833 0 0 1 9 25.5V13.583c0-1.012.82-1.833 1.833-1.833H14.5V9h11v2.75h3.667c1.012 0 1.833.82 1.833 1.833V25.5c0 1.013-.82 1.833-1.833 1.833H25.5V31h-11v-3.667zm1.833-15.583h7.334v-.917h-7.334v.917zm7.334 6.417v-1.834h3.666v1.834h-3.666z'/%3E%3C/g%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      transition: transform 0.3s linear;

      &:active {
        transform: scale(0.8);
      }
    }

    &--marker {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 20 18'%3E%3Cpath fill='%23333' fill-rule='nonzero' d='M19.775 4.614L15.43.227a.763.763 0 0 0-1.085 0L2.397 12.29a.78.78 0 0 0 0 1.097l.543.548-2.715 2.741a.781.781 0 0 0-.167.845c.12.29.4.479.71.479h4.344a.765.765 0 0 0 .543-.227l.543-.548.543.548a.76.76 0 0 0 1.086 0L19.775 5.71a.78.78 0 0 0 0-1.096zM4.815 16.715H2.1l1.755-1.772 1.357 1.37-.397.402zm2.47-.587l-3.258-3.29 8.688-8.773 3.258 3.29-8.689 8.773zm9.775-9.87l-3.258-3.29 1.085-1.096 3.26 3.29-1.087 1.097z'/%3E%3C/svg%3E%0A");
    }

    &--sticker {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath fill='%23333' fill-rule='nonzero' d='M.25 17.25V.75a.5.5 0 0 1 .5-.5h16.5a.5.5 0 0 1 .5.5v12.354a.5.5 0 0 1-.146.353l-4.147 4.147a.5.5 0 0 1-.353.146H.75a.5.5 0 0 1-.5-.5zm1.5-1h10.94l3.56-3.56V1.75H1.75v14.5zm10.5-4v4h-1.5v-5a.5.5 0 0 1 .5-.5h5v1.5h-4z'/%3E%3C/svg%3E%0A");
    }

    &--comment {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17'%3E%3Cpath fill='%23333' fill-rule='nonzero' d='M13 11.487h3.5V1.498h-15v9.99h8l3.5 2.55v-2.55zM18 .5v11.986a.5.5 0 0 1-.5.5h-3V16a.5.5 0 0 1-.794.405L9 12.985H.5a.5.5 0 0 1-.5-.5V.5A.5.5 0 0 1 .5 0h17a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E%0A");
    }
  }
}
