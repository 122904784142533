.async-image {
  position: relative;

  overflow: hidden;

  img {
    position: relative;
    left: 50%;

    height: 100%;
    transform: translateX(-50%);
  }
}
