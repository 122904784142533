@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

body {
  overflow-x: hidden;

  background-color: $background-content;
}

body.mobile {
  background-color: $green-default;
}

.app {
  &__header {
    background-color: $white-default;
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}
