@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.new-files-list {
  position: relative;

  &__header {
    display: flex;
    align-items: center;

    height: 24px;

    background: $files-list-title-bg;

    &__title {
      margin: 3px 10px 3px 24px;

      font-size: 13px;

      color: $gray-default;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;

    padding: 16px 0;
  }

  &__item {
    display: flex;
    margin-bottom: 24px;
  }

  &__preview {
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    max-width: 548px;

    margin: 0 25px;
  }

  &__menu {
    display: flex;
    margin-right: 8px;
    margin-left: auto;

    &__button {
      cursor: pointer;

      width: 24px;
      height: 24px;

      background-image: url('../../assets/images/export-img.svg');
    }
  }

  &__responsible {
    margin-bottom: 8px;

    font-family: $font-default;
    font-size: 13px;
    color: $gray-default;

    &--name {
      display: inline;

      color: $green-default !important;
      white-space: normal !important;
    }

    &--comma {
      color: $green-default;

      margin-right: 8px;
    }
  }

  &__description {
    max-height: 60px;
    margin-bottom: 6px;
    overflow: hidden;

    font-family: $font-default;
    font-size: 15px;

    color: $black-default;
    text-overflow: ellipsis;
  }

  &__date-uploaded {
    margin-bottom: 8px;

    font-size: 13px;

    time {
      color: $gray-default !important;
    }

    span {
      color: $gray-default !important;
    }
  }

  &__image-container {
    box-shadow: 0 0 8px $gray-border;
  }

  &__image {
    display: block;
    width: 46px;
    height: 62px;
    margin: 0 auto;

    img {
      z-index: -1;

      font-size: 10px;
      object-fit: cover;
    }
  }

  &__image-default {
    position: relative;
    z-index: -1;

    width: 46px;
    height: 62px;

    border: 2px solid $white-default;
  }

  &__image-default-ico {
    width: 36px;
    height: 24px;
    fill: $white-default;

    @include vertical-align-center;
  }

  &__show-more {
    margin-bottom: 60px;
  }

  &__link {
    position: relative;

    display: block;

    cursor: pointer;
  }

  &__button {
    position: absolute;
    top: 60px;
    right: 0;
  }

  &__type-labels {
    position: absolute;
    right: -8px;
    display: flex;
    flex-direction: row-reverse;
  }

  &__type-comment {
    
    width: 24px;
    height: 24px;
    margin-top: 45px;

    background-image: url('../../assets/images/comment-file-img.svg');

    background-position: center;
    background-repeat: no-repeat;
  }

  &__type-archive {

    width: 24px;
    height: 24px;
    margin-top: 45px;

    background-image: url('../../assets/images/archive-file-img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__type-new {
    width: 8px;
    height: 8px;

    background-color: $purple-bg;

    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  &__type-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 62px;
  }

  &__extension {
    position: absolute;

    width: 100%;

    margin-top: 52px;

    font-size: 9px;
    color: $gray-dark;
    text-align: center;
  }

  &__export-file-dropdown {
    min-width: 192px;
  }
}
