@import '../../styles/variables.scss';

.document-drawing-settings {
  padding: 12px 30px;

  background: $gray-default;

  &__content-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;
  }

  &__item {
    display: flex;
    width: 40px;
    height: 40px;

    border-radius: 4px;
    align-content: center;
    align-items: center;

    &--active {
      background: $gray-dark;
    }
  }

  &__button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: auto;

    border-radius: 50%;
  }

  &__range {
    width: 80px;
  }

  &__rubber-container {
    display: flex;
    width: 40px;
    height: 40px;

    border-radius: 4px;
    align-content: center;
    justify-content: center;
    align-items: center;

    &--active {
      background: $gray-dark;
    }
  }

  &__rubber {
    width: 28px;
    height: 20px;
    padding: 0;

    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='20' viewBox='0 0 28 20'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M11.174.585L.586 11.167A2 2 0 0 0 0 12.58V19a1 1 0 0 0 1 1h14.417a2 2 0 0 0 1.408-.579L27.407 8.943A2 2 0 0 0 28 7.523V1a1 1 0 0 0-1-1H12.588a2 2 0 0 0-1.414.585zm6.435 15.28l-1.624 1.61a2 2 0 0 1-1.409.58H2.9a1 1 0 0 1-1-1v-3.713a2 2 0 0 1 .602-1.43L5.297 9.18a2 2 0 0 1 1.398-.57H17.2a1 1 0 0 1 1 1v4.834a2 2 0 0 1-.591 1.42z'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }
}
