@import '../../styles/variables.scss';

.document-comments-layer {
  position: absolute;
  top: 0;
  z-index: 1;

  &__comment {
    position: absolute;

    display: inline-block;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;

    font-family: $font-bold;
    font-size: 13px;

    color: $white-default;
    text-align: center;

    background: $gray-dark;
    border-radius: 50%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
