@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.to-many-error {
  @include h1-bold;
  position: absolute;
  top: 50%;
  left: 50%;

  display: inline-block;

  color: $yellow-errors-default;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
