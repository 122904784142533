@import '../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.datepicker {
  position: relative;
}

.datepicker-days {
  @include days;
  width: 24px;
  height: 24px;
  margin: 4px;

  line-height: 24px;

  border-radius: 50% !important;

  &:not(.react-datepicker__day--disabled) {
    color: $default;
  }

  &__active {
    color: $white-default;

    background-color: $green-default;
  }

  &:hover {
    color: $default;

    background-color: $green-hover-text;
  }

  &__noactive {
    color: $white-default;

    background-color: $gray-hover;
  }
}
