@import 'variables.scss';

@mixin vertical-align-center {
  position: absolute;
  top: 50%;
  left: 50%;

  display: inline-block;
  transform: translate(-50%, -50%);
}

@mixin height100 {
  position: absolute;

  width: 100%;
  height: 100%;
}

@mixin h1 {
  font-family: $font-default;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;

  line-height: normal;
  letter-spacing: -1px;
  color: $black-default;
  font-stretch: normal;
}

@mixin h1-bold {
  @include h1;
  font-size: 24px;
  letter-spacing: -0.6px;
}

@mixin h2 {
  @include h1;
  font-family: $font-bold;
  font-size: 24px;
  letter-spacing: -0.6px;
}

@mixin h3 {
  @include h2;
  font-size: 17px;
  letter-spacing: -0.4px;
}

@mixin h4 {
  @include h2;
  font-size: 15px;
  letter-spacing: -0.2px;
}

@mixin h5 {
  @include h2;
  font-size: 13px;
  letter-spacing: normal;
}

@mixin h6 {
  @include h2;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

@mixin h7 {
  @include h6;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: normal;
}

@mixin status {
  @include h2;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

@mixin meeting-type {
  @include h2;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

@mixin name {
  @include h4;
  font-family: $font-default;
  font-style: normal;
}

@mixin text {
  @include name;
  font-family: $font-default;

  line-height: 1.87;
  letter-spacing: -0.2px;
}

@mixin menu {
  font-family: $font-bold;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;

  line-height: normal;
  letter-spacing: -1px;
  color: $black-default;
  font-stretch: normal;
}

@mixin button {
  @include h2;
  font-size: 15px;
  letter-spacing: -0.2px;
}

@mixin days {
  font-family: $font-default;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;

  line-height: 24px;
  letter-spacing: 0.4px;
  font-stretch: normal;
}
