@import '../../styles/mixins.scss';

.button-datepicker {
  min-width: 88px;
  padding-right: 31px;

  background: transparent;
  background-image: url('../../assets/images/calendar-img.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 15px 16px;
  border: none;
  outline: 0;

  &:first-letter {
    text-transform: uppercase;
  }

  @include name;

  &:focus,
  &:active {
    outline: 0;
  }
}
