@import '../../styles/variables.scss';

.avatar {
  display: inline-block;
  width: 36px;
  height: 36px;

  border-radius: 50%;
  object-fit: cover;

  &--size-36 {
    width: 36px;
    height: 36px;

    font-size: 12px;
  }

  &--size-100 {
    width: 100px;
    height: 100px;

    font-size: 24px;
  }

  &--initials {
    display: flex;

    color: $white-default;
    text-align: center;

    background-color: $green-default;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
