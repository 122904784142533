@import '../../styles/variables.scss';

.file-downloader {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__progress-bar {
    position: relative;

    width: 100%;
    height: 2px;

    margin-top: 8px;
    margin-right: 11px;
    margin-bottom: 8px;

    background-color: $gray-loader-bg;
    border-radius: 2px;
  }

  &__filler {
    width: 100%;
    height: 2px;

    background-color: $green-default;
    border-radius: 2px;
    transition: width 0.2s ease-in;

    &--aborted {
      background-color: $red-default !important;
    }
  }

  &__close-btn {
    cursor: pointer;

    &__ico {
      fill: $gray-default;

      width: 9px;
      height: 9px;
    }
  }
}
