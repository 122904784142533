@import '../../styles/variables.scss';

.profile {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);

  &__container {
    display: flex;
    flex-direction: column;

    width: 544px;
    max-height: 100vh;
  }

  &__header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;

    padding: 24px 0;

    background-color: $gray-bg;

    &__avatar {
      display: flex;
    }
    &__name {
      display: flex;
      margin-top: 8px;

      font-size: 26px;
      color: $black-default;
    }
    &__position {
      display: flex;
      margin-top: 8px;

      font-size: 15px;
    }
    &__positionInCommittee {
      display: flex;
      margin-top: 10px;

      font-size: 15px;
      color: $gray-default;
    }
    &__messengers {
      display: flex;
      min-height: 64px;
      margin-top: 30px;
      flex-direction: row;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__icon {
        display: flex;

        width: 40px;
        height: 40px;

        margin: 0 16px;

        border: 1px solid $gray-border;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        &--active {
          background-color: $green-default !important;
          border: 1px solid $green-default !important;
        }

        &--active-tamtam {
          background-color: $blue-bg-button !important;
          border: 1px solid $blue-bg-button !important;
        }

        &__message {
          width: 24px;
          height: 24px;

          background-image: url('../../assets/images/comment-profile-img.svg');

          &--active {
            background-image: url('../../assets/images/comment-profile-active-img.svg');
          }
        }
        &__mobile {
          width: 24px;
          height: 24px;

          background-image: url('../../assets/images/phone-profile-img.svg');

          &--active {
            background-image: url('../../assets/images/phone-profile-active-img.svg');
          }
        }
        &__mail {
          width: 24px;
          height: 24px;

          background-image: url('../../assets/images/email-profile-img.svg');

          &--active {
            background-image: url('../../assets/images/email-profile-active-img.svg');
          }
        }
        &__tamtam {
          width: 24px;
          height: 24px;

          background-image: url('../../assets/images/tamtam-profile-img.svg');

          &--active {
            background-image: url('../../assets/images/tamtam-profile-active-img.svg');
          }
        }
      }

      &__text {
        margin-top: 8px;

        font-size: 11px;
        color: $gray-hover;

        &--active {
          color: $green-default !important;
        }
      }
    }
  }

  &__body {
    padding-bottom: 35px;
    overflow: auto;

    background-color: $white-default;
  }

  &__contacts-list {
    &__item {
      padding: 10px 0;
      margin-left: 16px;

      border-bottom: 0.4px solid $list-item-divider;

      &__value {
        font-size: 17px;
        font-weight: lighter;
        color: $black-default;

        &--active {
          color: $green-default;
        }
      }

      &__title {
        font-size: 15px;
        font-weight: lighter;
        color: $gray-default;
      }
    }
  }

  &__management-group {
    margin-top: 24px;

    &__title {
      margin: 22px 0 12px 16px;

      font-size: 14px;
      font-weight: bold;
      color: $black-default;
    }
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 20px;

    display: block;
    width: 14px;
    height: 13px;
    padding: 0;

    background-color: transparent;
    background-image: url('../../assets/images/x-img.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__info {
    padding: 30px;
    overflow: hidden;

    background: $purple-default;
  }

  &__avatar {
    float: left;
  }

  &__name {
    margin-left: 125px;

    font-family: $font-bold;
    font-size: 34px;
    font-weight: bold;

    color: $white-default;
  }

  &__addition {
    padding: 35px 30px;

    background: $white-default;
  }

  &__addition-content {
    display: flex;
  }

  &__column {
    display: flex;
    width: 50%;
    flex-direction: column;

    &--right {
      text-align: right;
    }
  }

  &__column-content {
    display: block;
    margin-bottom: 30px;

    font-family: $font-default;
    font-size: 15px;

    color: $black-default;

    background: transparent;

    &--version {
      color: $gray-default;
    }

    &--locale {
      width: auto;
      margin-left: auto;

      color: $green-default;
      text-align: right;
      outline: none;
    }
  }

  &__logout-button {
    display: block;
    margin: 15px 0 15px 10px;
  }
}
