@import '../../styles/variables.scss';

.meeting-list-item {
  &.current {
    background: $white-default;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  }

  &--upcoming {
    background: $gray-box;
  }

  &--today {
    background: $white-default;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  }

  &--passed,
  &--results {
    background: $gray-bg;
    border: 1px solid $gray-border;
  }

  &__tagging-container {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 32px;
    height: 32px;

    background: $green-default;
    border-radius: 50%;
  }

  &--upcoming &__tagging-container {
    background: $green-ava;
  }

  &--today &__tagging-container {
    background: $green-default;
  }

  &--passed &__tagging-container {
    background: $gray-border;
  }

  &__title {
    width: 86.20689655172413%;
    padding-bottom: 10px;

    font-family: $font-default;
    font-size: 15px;

    color: $black-default;
  }

  &--passed &__title {
    color: $gray-default;
  }

  &__status {
    font-family: $font-semi-bold;
    font-size: 13px;

    color: $green-default;
    text-transform: uppercase;
  }

  &--passed &__status {
    color: $gray-default;
  }

  &__time-container {
    display: inline-block;
    padding-bottom: 10px;
  }

  &__tagging {
    position: absolute;
    top: 50%;
    left: 50%;

    font-family: $font-default;
    font-size: 11px;

    color: $white-default;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
  }

  &__files-container {
    position: absolute;
    right: 16px;
    bottom: 19px;
  }

  &__files-counter {
    display: inline-block;
    float: left;
    padding-right: 5px;

    font-size: 13px;

    color: $gray-default;
  }

  &__files-clip {
    position: relative;

    display: inline-block;
    width: 8px;
    height: 14px;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='14' viewBox='0 0 7 14'%3E%3Cpath fill='%23C8C8C8' fill-rule='evenodd' d='M7 2.256l-.001 7.767c0 5.35-6.999 5.254-6.999 0V2.256C0-.752 4.107-.752 4.108 2.256v7.767c0 .755-1.214.755-1.214 0V2.256c0-1.505-1.678-1.506-1.677 0l-.001 7.767c0 3.763 4.569 3.761 4.57 0V2.257c0-.71 1.215-.71 1.215 0z'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }
}
