@import '../mixins.scss';

.login-form {
  width: 340px;

  text-align: center;

  &__label,
  &__error {
    @include name;
    display: block;

    color: $white-default;

    &--large {
      font-size: 34px !important;
    }
  }

  &__error {
    color: $yellow-errors-default;
  }

  &__input,
  &__input {
    width: 85%;
    margin: 0 auto;

    font: 400 32px $font-default !important;
    letter-spacing: 1px;
    color: $white-default !important;

    background-color: $green-default !important;
    border: 0 none;
    border-color: $green-default;
    outline: 0;
  }

  &__input--sms {
    display: block;
    width: 160px;
    padding-left: 40px;
    margin: 0 auto;

    border-bottom: 2px solid white;
  }

  &__content-container {
    display: flex;
    align-items: center;

    border-bottom: 2px solid $white-default;
  }

  &__content-absent {
    display: flex;
    height: 100%;

    min-width: 510px;
    flex-direction: column;
    align-items: center;

    text-align: center;

    &__title {
      margin-bottom: 14px;
    }
  }

  &__code-country {
    display: inline-block;
    padding-left: 35px;

    font: 400 32px $font-default;
    color: $white-default;
  }

  &__button {
    margin-top: 40px;
  }

  &__request-new {
    display: block;

    & > * {
      @include name;
      display: inline-block;
      margin-top: 22px;

      font-size: 13px;
      font-weight: 600;

      color: $white-default;
    }

    & > a {
      color: $purple-default;
      text-decoration: underline;
      outline: 0;
    }
  }
}
