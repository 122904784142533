@import '../../styles/variables.scss';

.period {
  &:first-letter {
    text-transform: uppercase;
  }

  &__time {
    font-family: $font-default;
    font-size: 13px;

    color: $black-default;
  }

  &__gmt {
    font-family: $font-default;
    font-size: 9px;

    color: $black-default;
  }
}
