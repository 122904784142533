@import '../../styles/variables';

.members-filters {
  background: $white-default;

  &__item {
    margin-bottom: 24px;

    font-family: $font-bold;

    border-left: 2px solid transparent;

    &:last-child {
      margin-bottom: 0;
    }

    &--active {
      border-left: 2px solid $green-default;
    }
  }

  &__button {
    padding: 0;
    margin-left: 30px;

    font-family: $font-default;
    font-size: 15px;

    color: $black-default;
    text-align: left;

    background: transparent;
  }

  &__item--active &__button {
    font-family: $font-semi-bold;
  }

  &__tab-list {
    padding-top: 18px;
    padding-left: 12px;
    margin: 0;

    border-bottom: 1px solid $gray-hover;
  }

  &__tab {
    padding-bottom: 18px;
    margin: 0 12px;

    font-size: 15px;
    font-weight: lighter;
    color: $default;

    background: transparent;

    &--selected {
      font-weight: normal;

      border-bottom: 2px solid $green-default;
    }

    &:focus {
      box-shadow: none;

      border-color: $green-default;
      outline: none;
    }
    &:focus:after {
      position: absolute;
      right: -4px;
      bottom: -5px;
      left: -4px;

      height: 5px;

      background: transparent;
      content: '';
    }
  }

  &__list {
    &__item {
      cursor: pointer;

      padding: 18px 24px;

      font-size: 15px;
      font-weight: lighter;
      color: $default;

      &--selected {
        font-weight: normal;

        background-color: $gray-box;
      }

      &:hover {
        background-color: $gray-box;
      }
    }
  }
}
