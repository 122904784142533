.document {
  position: absolute;

  display: flex;
  height: 100%;
  flex-direction: column;

  &__content-container {
    position: relative;

    display: flex;
    height: 100%;
    overflow: hidden;
    align-content: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &__document-preview {
    width: 100%;
    margin: 20px auto;
  }

  &__document-pdf {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__small-column {
    width: 13%;
    min-width: 140px;
    padding-top: 20px;

    &--hidden {
      display: none;
    }
  }

  &__large-column {
    padding: 20px 0 0;
  }

  &__small-column--hidden + &__large-column {
    margin-left: 30px !important;
  }

  &__small-column + &__large-column {
    margin-left: 0;
  }

  &__medium-column {
    width: 100%;
    max-width: 310px;
    margin-left: 20px;
  }
}

.document-pdf {
  &__page {
    position: relative;
  }
}

.container-document {
  width: 100%;
  height: 100%;
}
