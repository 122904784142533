@import '../../styles/variables.scss';

.members-list {
  &__title {
    padding-bottom: 40px;

    font-family: $font-bold;
    font-size: 24px;

    color: $black-default;
  }

  &__list-title {
    padding: 12px 0;

    font-size: 21px;
    font-weight: bolder;
    color: $black-default;

    &--number {
      color: $gray-default;
    }
  }

  &__list-spacer {
    width: 100%;
    height: 26px;
  }

  &__image {
    display: inline-block;
    width: 48px;
    height: 48px;
    object-fit: cover;

    border-radius: 50%;
  }

  &__name {
    display: inline-block;
    padding-left: 16px;

    font-family: $font-default;
    font-size: 15px;
    font-weight: lighter;

    color: $black-default;
  }

  &__item {
    display: inline-block;
    width: 50%;
    padding-bottom: 30px;
  }

  &__list--display-list {
    .members-list__item {
      width: 100%;
    }
  }
}

.no-members {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
  &__image {
    width: 72px;
    height: 52px;
    object-fit: contain;

    background-image: url('../../assets/images/members-img.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
  &__message {
    margin-top: 26px;

    font-size: 20px;
    color: $gray-default;
  }
}
