@import 'variables.scss';

.meeting-button {
  padding: 0;

  font-family: $font-default;
  font-size: 15px;

  color: $green-default;

  background: transparent;
}

.container {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: center;

  &--full-height {
    min-height: calc(100vh - 86px);
  }

  &--with-padding-top {
    padding-top: 60px;
  }

  &__small-column {
    position: relative;

    width: 380px;
    height: 100%;
    max-height: 100%;
  }

  &__large-column {
    width: 700px;
    height: 100%;
    min-height: 500px;
    margin: 0 0 0 60px;
    flex-shrink: 2;
  }

  &__document {
    width: 100%;
    height: 100%;
    max-width: 1200px;
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
.ReactModal__Overlay {
  z-index: 10;
}
