@import '../../styles/variables.scss';

.files-filter {
  position: relative;

  &__list {
    position: relative;

    padding: 25px 30px 0;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;

      width: 100%;
      height: 2px;
      content: '';

      background: $gray-bg;
    }
  }

  &__item {
    position: relative;
    z-index: 1;

    display: inline-block;
    padding-bottom: 23px;
    margin-right: 40px;

    border-bottom: 2px solid transparent;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      border-bottom: 2px solid $green-default;
    }
  }

  &__button {
    padding: 0;

    font-family: $font-semi-bold;
    font-size: 15px;

    color: $black-default;

    background: transparent;
    outline: 0;
  }
}
