@import '../../styles/variables.scss';

$list-item-padding-vert: 20px;
$list-item-padding-hor: 30px;

.user {
  position: relative;

  padding: $list-item-padding-vert $list-item-padding-hor;

  font-size: 18px;

  & &__name {
    font-size: 24px;
    font-weight: bold;
  }

  &__photo {
    width: $user-list-photo-width;
    height: $user-list-photo-height;
    margin-right: 20px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }

  &__phone {
    position: absolute;
    right: $list-item-padding-hor;
    bottom: $list-item-padding-vert;
  }
}
.invheader {
  color: transparent !important;
}

.userList th,
.userList td {
  font-size: 13px;
  color: #c8c8c8;
  font-weight: 400;
  vertical-align: middle;
}
.userList th {
  font-family: 'SFProText-Regular', 'HevelticaNeue', Helvetica, sans-serif !important;
  padding-bottom: 4px;
}

.user-head-row {
  border-bottom: 1px solid #c8c8c8;
  -webkit-font-smoothing: antialiased !important;
  padding: 15px;
}

.user-item-row {
  border-bottom: 1px solid #c8c8c8;
  -webkit-font-smoothing: antialiased !important;
  height: 80px;
  color: #000;
}

.user-item-edit {
  float: right;
  font-size: 15px;
  padding-right: 16px;
}

.user-item-row td .user-name {
  -webkit-font-smoothing: antialiased !important;
  font-size: 17px;
  color: #000;
  margin-top: 2px;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.user-item-row td .user-role {
  font-size: 15px;
  color: #999;
  -webkit-font-smoothing: antialiased !important;
}
.tick-box {
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  color: black !important;
}
.user-list-avatar-box {
  border-bottom: 1px solid #fff;
}

.user-list-avatar-box .user__photo {
  margin-right: 10px;
  margin-left: 24px;
}
.total-user-count {
  color: #999;
}
.check {
  width: 24px;
}
.delete-pointer {
  cursor: pointer;
}

.page-users .btn-default.active {
  background: #000 !important;
  color: #fff !important;
}

.page-users .container-fluid {
  padding-left: 30px;
  padding-right: 40px;
}

.pointer {
  cursor: pointer;
}
