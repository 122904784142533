$background-content: #ededed;
$font-default: 'Open Sans Regular';
$font-semi-bold: 'Open Sans Semibold';
$font-bold: 'Open Sans Bold';
$default: #333;
$black-default: #333;
$black-hover: #000;
$gray-dark: #727272;
$gray-default: #999;
$gray-hover: #c8c8c8;
$gray-border: #d8d8d8;
$gray-bg: #ededed;
$gray-box: #f7f7f7;
$gray-loader-bg: #e6e6e6;
$white-default: #fff;
$green-default: #00b956;
$green-disabled: #009444;
$green-buttons-hover: #34c778;
$green-ava: #73e6a8;
$green-hover-text: #d7fce8;
$green-hover-menu: #d7fce8;
$purple-default: #731982;
$purple-disabled: #784082;
$purple-bg: #b108cf;
$purple-button-hover: #b108cf;
$red-default: #ff1717;
$red-links: #f62434;
$red-errors: #f62434;
$yellow-errors-default: #ff6;
$yellow-errors-light: #ffffb3;
$orange-bg: #ffa717;
$blue-bg-marker: #34bbff;
$pink-bg-marker: #f6f;
$red-bg-marker: #ff6;
$orange-bg-marker: #f93;
$green-bg-marker: #29cc29;
$yellow-bg-marker: #e6e65c;
$white-bg-marker: #fff;
$checkbox-bg: #d8d8d8;
$green-export-button: #14892c;
$files-list-title-bg: #e7e7e7;
$list-item-divider: #c7c7cc;
$blue-bg-button: #445eff;
