.lds-ring-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
}

.lds-ring {
  position: absolute;
  position: relative;
  top: 50%;
  left: 50%;

  display: inline-block;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);
}

.lds-ring div {
  position: absolute;

  display: block;
  width: 51px;
  height: 51px;
  margin: 6px;

  border: 6px solid #fff;
  border-color: #fff transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
