@import '../../styles/variables.scss';

.document-preview {
  height: 100%;

  &__item {
    text-align: center;
  }

  &__preview {
    cursor: pointer;

    display: inline-block;
    object-fit: cover;

    border: 1px solid $gray-border;
  }

  &__item &__preview {
    border: 1px solid transparent;
  }

  &__item--active &__preview {
    border: 1px solid $green-default;
  }

  &__number {
    padding-top: 5px;

    font-family: $font-default;
    font-size: 13px;

    color: $black-default;
    text-align: center;
  }
}
