@import '../../styles/variables.scss';

.files-checkboxes {
  padding: 40px 30px 20px;

  &__label {
    position: relative;

    display: block;
    padding-left: 35px;
    margin-bottom: 30px;

    font-family: $font-default;
    font-size: 15px;

    color: $black-default;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    background: $white-default;
    border: 1px solid $green-default;
    border-radius: 2px;

    &:after {
      position: absolute;

      display: none;
      content: '';
    }
  }

  &__label &__checkmark:after {
    top: 5px;
    top: 50%;
    left: 9px;
    left: 50%;

    width: 12px;
    height: 10px;
    content: '';

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 13 10'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M11 0L4.8 7.4 1.2 3.633 0 4.833C3.2 8.144 4.822 9.8 4.867 9.8c.044 0 2.489-2.889 7.333-8.667L11 0z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  &__checkbox:checked ~ &__checkmark {
    background: $green-default;
  }

  &__checkbox:checked ~ &__checkmark:after {
    display: block;
  }
}
