@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.button {
  @include button;
  min-width: 160px;

  font-family: $font-default;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;

  line-height: 46px;
  letter-spacing: -0.4px;
  color: $white-default;
  text-align: center;

  background-color: $purple-default;
  border-radius: 23px;
  font-stretch: normal;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: $green-disabled;
  }

  &:hover {
    background-color: $purple-button-hover;
  }

  &--big {
    min-width: 210px;

    font-size: 17px;

    line-height: 60px;

    border-radius: 30px;
  }

  &--green {
    background-color: $green-default;
  }

  &--green:hover {
    background-color: $green-buttons-hover;
  }

  &:disabled span {
    opacity: 0.5;
  }
}
