.scroll-block {
  position: absolute;
  right: 0;
  left: 0;

  max-height: 100%;
  overflow-y: auto;

  &__preloader {
    width: 100px;
    margin: 0 auto;
  }

  &__list-group {
    margin: 0;
  }
}
