.members {
  align-items: flex-start;

  &__members-filters {
    width: 380px;
    height: calc(100vh - 128px);
    overflow: auto;
  }

  &__members-list {
    display: block;
    width: 700px;
    height: calc(100vh - 128px);
    margin-left: 60px;
  }
}

.user-list-avatar-box {
  border-bottom: 1px solid #ededed !important;
}
