@import '../../styles/variables.scss';

.list-group {
  &__title {
    padding-bottom: 20px;
    padding-left: 16px;

    font-family: $font-semi-bold;
    font-size: 13px;
    font-weight: lighter;

    color: $gray-default;
    text-transform: uppercase;
  }

  &__meeting-list-item {
    position: relative;

    padding: 20px 16px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 40px;
    }
  }
}
